import PropertyFieldsModal from '@/components/modals/PropertyField.modal';
import PropertiesTable from '@/components/tables/Properties.table';
import { RawProperty } from '@/interfaces/property.interface';
import APIService from '@/services/API';
import { ShopOutlined, HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Divider, Modal, Row, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const PropertiesScreen = () => {
  const { t } = useTranslation();
  const nav = useNavigate();

  const [params, setParams] = useState({
    $skip: '0',
    $limit: '10',
    '$sort[created_at]': '-1',
  });
  const [modal, setModal] = useState({
    type: 'create',
    visible: false,
    data: {},
  });
  const { isLoading, data, refetch } = useQuery(['properties', params], () =>
    APIService.getProperties(params),
  );
  const handleDelete = async (id: string) => {
    const response = await APIService.deleteProperty(id);
    if (response.ok) {
      refetch();
      message.success(t('general.deleteSuccess'));
    } else {
      message.error(response.data?.message);
    }
  };
  const onDelete = (id: string) => {
    Modal.confirm({
      title: t('general.deleteConfirmation'),
      okText: t('general.yes'),
      onOk: () => handleDelete(id),
    });
  };
  const onEdit = (data: RawProperty) => {
    // Hanlde edit user, open edition modal
    nav(`/dashboard/properties/${data._id}`);
  };
  return (
    <div className="dashboard-screen fadeIn">
      <PropertyFieldsModal
        visible={modal.visible}
        edit={modal.type === 'edit'}
        data={modal.data}
        onOk={() => {
          refetch();
          setModal({ visible: false, type: 'create', data: {} });
        }}
        onCancel={() => {
          setModal({ visible: false, type: 'create', data: {} });
        }}
      />
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <ShopOutlined />
                      <span>{t('menu.properties')}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={() => nav('/dashboard/properties/new')}>
              {t('general.create')}
            </Button>
          </Col>
        </Row>
        <Divider />
        <PropertiesTable
          onFilter={setParams}
          data={data?.data}
          loading={isLoading}
          total={data?.total}
          current={(data?.skip || 0) / (data?.limit || 1)}
          {...{ onDelete, onEdit }}
        />
      </Card>
    </div>
  );
};

export default PropertiesScreen;
