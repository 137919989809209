import { Button, Table, Space, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { parseFilter } from '@/utils/filter';
import { useTranslation } from 'react-i18next';
import { Property, RawProperty, Categories } from '@/interfaces/property.interface';
import { FilterByRegex, FilterByStatus, FilterByIcon } from '../utils/CommonFilters';

const getPreviewUrl = () => import.meta.env.VITE_APP_PREVIEW_URL;

export interface PropertiesTableProps {
  data?: RawProperty[];
  total?: number;
  current?: number;
  loading?: boolean;
  onFilter?: (value: any) => any;
  onEdit?: (data: RawProperty) => void;
  onView?: (data: Property) => void;
  onDelete?: (id: string) => void;
}

const STATUS_COLOR = {
  publish: 'green',
  'soft-delete': 'red',
  pending: 'red',
  draft: 'blue',
};

const PropertiesTable = ({
  data,
  total,
  loading,
  onFilter,
  onDelete,
  onEdit,
}: PropertiesTableProps) => {
  const { t, i18n } = useTranslation();

  const Columns: ColumnsType<RawProperty> = [
    {
      title: t('general.title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (_id, row) => {
        return (
          <Button
            type="link"
            rel="noreferrer"
            href={`${getPreviewUrl()}/${i18n.language as 'en' | 'es'}/property/${
              row.slug[i18n.language as 'en' | 'es']
            }`}
            target="_blank"
          >
            {row.title[i18n.language as 'en' | 'es']}
          </Button>
        );
      },
      filterDropdown: FilterByRegex,
      filterIcon: FilterByIcon,
    },
    {
      title: t('general.contractType'),
      dataIndex: 'categories',
      key: 'categories',
      render: (category: Categories) => {
        let defaultCategory = 'NA';
        const categoryMeta = i18n.language === 'en' ? 'contract-type' : 'tipo-contrato';
        const target = category[i18n.language as 'en' | 'es'].find(
          (item) => item.category === categoryMeta,
        );
        if (target) {
          defaultCategory = target.meta
            .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
            .join(', ');
        }
        return defaultCategory;
      },
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: keyof typeof STATUS_COLOR) => {
        return <Tag color={STATUS_COLOR[status]}>{t(`status.${status}`)}</Tag>;
      },
      filters: [
        {
          text: t('status.publish'),
          value: 'publish',
        },
        {
          text: t('status.draft'),
          value: 'draft',
        },
      ],
      filterDropdown: FilterByStatus([
        {
          label: t('status.publish'),
          value: 'publish',
        },
        {
          label: t('status.draft'),
          value: 'draft',
        },
      ]),
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (created_at: string) =>
        created_at && new Date(created_at).toLocaleDateString(),
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (text: string, record: RawProperty) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit?.(record)}>
            {t('general.edit')}
          </Button>
          <Button type="link" onClick={() => onDelete?.(record._id)}>
            {t('general.delete')}
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (pagination: any = {}, filters: any = {}, sorter: any) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...parseFilter(filters, value, sorter),
        language: i18n.language,
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={Columns}
      loading={loading}
      onChange={handleOnChange}
      rowKey={(e) => e._id}
      pagination={{
        total,
        defaultPageSize: 10,
        showTotal: (total) => t('general.total', { total }),
      }}
    />
  );
};

export default PropertiesTable;
